<template>
  <div class="create-brand" v-cloak>
    <div class="container">
      <transition
        name="fade"
        mode="out-in"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter"
      >
        <div key="1" v-if="loader" class="white-row loader-route">
          <div class="lds-sub-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
        <div v-else key="2" class="white-row">
          <div class="convert-leadto-client-form form-area">
            <div class="col-sm-12">
                <div
              class="wrapper-notifications"
              >
                <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="status-left-side">
                      <h5 class="sub-heading">Project Account Manager :</h5>
                      <div class="">
                        <div class="stauts-form form-area">
                          <form
                            class="am-form"
                            v-on:submit.prevent="onAddAccountManager($event)"
                          >
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="form-group">
                                  <label for="gender" class="control-label"></label>
                                  <Select2
                                    v-model="formData.am_id"
                                    :options="project_am_options"
                                    :settings="{
                                      placeholder: 'Add Account Manager',
                                      multiple: true,
                                      closeOnSelect: false
                                    }"
                                    :class="{
                                      'input-border-errors':
                                        $v.formData.am_id.$dirty &&
                                        !$v.formData.am_id.required
                                    }"
                                  />
                                </div>
                              </div>
                              <div class="form-actions">
                                <button
                                  :disabled="disableSubmitBtnAm"
                                  type="submit"
                                  id="save-form"
                                  class="default-global-btn"
                                >
                                  Add AM
                                </button>
                                <div v-if="disableSubmitBtnAm" class="form_submit_loader">
                                  <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>AM Name</th>
                              <th>AM Email</th>
                              <th class="text-right">Action</th>
                            </tr>
                          </thead>
                          <tbody
                            v-if="
                              typeof get_client_am_listing.data !== 'undefined' &&
                                Object.keys(get_client_am_listing.data).length
                            "
                          >
                            <tr
                              v-for="(am_data, am_index) in get_client_am_listing.data"
                              :key="am_index"
                            >
                              <td>
                                {{ am_data.name }}
                              </td>
                              <td>
                                <p class="invoice-total bold-cls">
                                  {{ am_data.email }}
                                </p>
                              </td>
                              <td>
                                <div class="download-box text-right">
                                  <a
                                    data-toggle="tooltip"
                                    title="Delete!"
                                    class="invoice-icon"
                                    data-placement="top"
                                    v-confirm="{
                                      loader: true,
                                      ok: dialog =>
                                        onDeleteAccountManager(dialog, am_index),
                                      cancel: doNothing,
                                      message: 'Are you sure, you want to delete ?'
                                    }"
                                    href="#"
                                  >
                                    <p class="invoice-icon">
                                      <i class="fas fa-times"></i>
                                    </p>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="3">
                                <no-record-found></no-record-found>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>    
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import norecordfound from "../../../components/no-record-found";

import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";

const {
  required,
  minLength,
  email,
  numeric,
  url
} = require("vuelidate/lib/validators");


export default {
  mixins: [validationMixin],
  name : "auto-repy-get-client-details",
  components: {
    "no-record-found": norecordfound,
    Select2
  },
  validations: {
    project_am_option_val: {
      required
    },
    formData : {
      am_id : {
        required
      },
    }
  },
  watch: {
    prop_auto_reply_message_id: {
      handler(val){
        if(val) {
          this.loader = false;
          this.getClientAmList();
        }
      },
      deep: true
    },
    prop_auto_reply_message_body: {
      handler(val){
        if(val) {
          this.formData.message_body = val;
        }
      },
      deep: true
    },
  },
  props: [
    'prop_client_id',
  ],
  data() {
    return {
      disableSubmitBtn : false,
      loader: true,
      formData: {
        client_id : this.prop_client_id,
        am_id : null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      deleteFormData: {
        client_id : this.prop_client_id,
        am_id : null,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      get_client_am_listing: {},

      project_am: {},
      project_am_options: null,
      project_am_option_val: null,
      project_am_loader: true,
      disableSubmitBtnAm : false,
    };
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  mounted() {
    // this.loader = false;
    this.getClientAmList();
    this.getprojectaccountmanagerfileter();
  },
  methods: {
    async onDeleteAccountManager(dialog, dataDetails) {
      // alert("hi");
      dialog.close();
      const $this = this;
      $this.deleteFormData.am_id = this.get_client_am_listing.data[dataDetails].id;
      const data = $this.$store.dispatch("postHttp", {
        url: "clients/remove-am",
        formDetails: $this.deleteFormData,
        formEvent: ""
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: response.data.message,
            type: "success"
          });
          // console.log(response);
          $this.get_client_am_listing = response.data.am_list;
          $this.$emit('updateClientAmData', response.data.client);
          $this.getprojectaccountmanagerfileter();
          $this.deleteFormData.am_id = null;
        }
      });
    },
     async getprojectaccountmanagerfileter() {
      const $this = this;
      const getProjectAms = $this.$store.dispatch("getHttp", {
        url:
          "/filters/get-employees-listing?client_id=" +
          this.prop_client_id
      });
      await getProjectAms.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_am_options = response.data.data;
        }
      });
    },
    async onAddAccountManager(event) {
      const $this = this;
      $this.disableSubmitBtn = true;
      $this.$v.formData.am_id.$touch();

      if ($this.$v.formData.am_id.$invalid) {
        $this.$store.dispatch("showtostrMessageNotifications", {
          message: "Please fill all the fields to proceed form!",
          type: "error"
        });
        $this.disableSubmitBtn = false;
        return;
      }
      

      const data = $this.$store.dispatch("postHttp", {
        url: "clients/add-am",
        formDetails: this.formData,
        formEvent: event.target
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.$store.dispatch("showtostrMessageNotifications", {
            message: response.data.message,
            type: "success"
          });
          // console.log(response);
          $this.get_client_am_listing = response.data.am_list;
          $this.$emit('updateClientAmData', response.data.client);
          $this.getprojectaccountmanagerfileter();
          $this.formData.am_id = null;
          $this.$v.formData.am_id.$reset();
        }
      });
      $this.disableSubmitBtn = false;
    },
    refreshBrandTable() {
      this.getClientAmList();
    },
    doNothing: function() {
      // Do nothing or some other stuffs
    },

    async getClientAmList() {
      const $this = this;
      $this.data_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "clients/am-listing/" +
          this.prop_client_id + 
          "?timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.get_client_am_listing = response.data;
          // $this.formData.user_id = response.data.data.id;
          $this.loader = false;
        }
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    async onSubmit(event,lead_index) {
      
    //   const $this = this;
    //   $this.disableSubmitBtn = true;
    //   $this.$v.$touch();

    //   if ($this.$v.$invalid) {
    //     $this.$store.dispatch("showtostrMessageNotifications", {
    //       message: "Please fill all the fields to proceed form!",
    //       type: "error"
    //     });
    //     $this.disableSubmitBtn = false;
    //     return;
    //   }

    //   const data = $this.$store.dispatch("postHttp", {
    //     url: "leads/convert-lead-to-client",
    //     formDetails: this.formData,
    //     formEvent: event.target
    //   });
    //   await data.then( (response) => {
    //     if (typeof response !== "undefined") {
    //       // console.log(response.data[0]);
    //       $this.$store.dispatch("showtostrMessageNotifications", {
    //         message: response.data.message,
    //         type: 'success'
    //       });
    //       this.$emit('updateLeadData', response.data[0]);
    //     }
    //   });
    //   $this.disableSubmitBtn = false;
    },
  }
};
</script>
<style lang="scss" scoped>

.form-check {
    clear: both;
    margin-bottom: 20px;
}
body .white-row.loader-route {
  top: 0;
}
body .white-row {
    padding: 30px 0 0;
    box-shadow: unset;
}

</style>