<template>
  <div class="employee-index">
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else key="1" class="white-row">
        <!-- // Filter Area -->
        <div class="general-filters">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div class="row top-gen-heading">
                  <h1 class="global-style-heading">
                    Clients
                    <a
                      @click.prevent="refreshClientTable"
                      href=""
                      class="refreshData"
                      ><i class="fas fa-redo-alt"></i
                    ></a>
                  </h1>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <div class="form-actions">
                    <router-link
                      type="submit"
                      id="save-form"
                      class="btn btn-primary"
                      :to="{
                        name: 'clients.create'
                      }"
                      tag="button"
                    >
                      <i class="fas fa-plus-circle"></i> Add New Client
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form @submit.prevent="getClientData(1)">
                  <div class="row">
                    
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="form-group custom-date-picker custom-padding">
                          <label for="from_date">&nbsp;</label>
                          <datepicker
                            :minimumView="'day'"
                            :maximumView="'month'"
                            :initialView="'month'"
                            :format="customFormatterfromdate"
                            placeholder="From"
                            :input-class="'form-control'"
                            :clear-button="true"
                            :value="formData.from_date"
                            @cleared="onClearDateFromDate"
                          >
                            <span
                              slot="afterDateInput"
                              class="animated-placeholder"
                            >
                              <i class="far fa-calendar-alt"></i>
                            </span>
                          </datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="form-group custom-date-picker custom-padding">
                          <label for="to_date">&nbsp;</label>
                          <datepicker
                            :minimumView="'day'"
                            :maximumView="'month'"
                            :initialView="'month'"
                            :format="customFormattertodate"
                            placeholder="To"
                            :input-class="'form-control'"
                            :clear-button="true"
                            @cleared="onClearDateToDate"
                            :value="formData.to_date"
                          >
                            <span
                              slot="afterDateInput"
                              class="animated-placeholder"
                            >
                              <i class="far fa-calendar-alt"></i>
                            </span>
                          </datepicker>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                        <div class="form-group">
                          <label for="gender" class="control-label"
                            >&nbsp;</label
                          >
                          <div class="input-group">
                            <input
                              v-model="formData.search"
                              @keyup.enter="getClientData(1, 'search')"
                              type="text"
                              class="form-control"
                              placeholder="Press Enter For Search..."
                            />
                            <div class="input-group-append">
                              <button class="btn btn-secondary" type="button">
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                          <div class="form-group">
                            <label for="brand_id" class="control-label"
                              >&nbsp;</label
                            >
                            <Select2
                              @change="getClientData(1)"
                              v-model="formData.brand_id"
                              :options="brand_options"
                              :settings="{
                                placeholder: 'Select Brand',
                                allowClear: true
                              }"
                            />
                          </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                          <div class="form-group">
                            <label for="status" class="control-label"
                              >&nbsp;</label
                            >
                            <Select2
                              @change="getClientData(1)"
                              v-model="formData.status"
                              :options="status_options"
                              :settings="{
                                placeholder: 'Select Status',
                                allowClear: true
                              }"
                            />
                          </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                          <div class="form-group">
                            <label for="brand_id" class="control-label"
                              >&nbsp;</label
                            >
                            <Select2
                              @change="getClientData(1)"
                              v-model="formData.employee_id"
                              :options="project_employees"
                              :settings="{
                                placeholder: 'Sales Person / Sales Team Lead',
                                allowClear: true
                              }"
                            />
                          </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                        <div  class="form-actions">
                          <button type="submit" class="btn btn-primary default-global-btn"> Search </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="horizontal-row">
          <hr />
        </div>
        <div class="emp-listing">
          <transition
            name="fade"
            mode="out-in"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
          >
            <div key="1" v-if="client_loader" class="white-row loader-route">
              <div class="lds-sub-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
            <div v-else class="client-page-wrapper">
              <div
                class="row"
                v-if="
                  typeof client_list.data !== 'undefined' &&
                    Object.keys(client_list.data).length
                "
              >
                <div
                  v-for="(client, cl_index) in client_list.data"
                  :key="cl_index"
                  class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                >
                  <div class="wrap-box">
                    <div class="header-top">
                      <dropdown>
                        <template slot="icon"
                          ><i class="fas fa-ellipsis-v"></i
                        ></template>
                        <template slot="body">
                          <ul class="list-group">
                            <li class="list-group-item">
                              <router-link
                                :to="{
                                  path: '/clients/edit/' + client.id
                                }"
                                tag="a"
                              >
                                Edit
                              </router-link>
                            </li>
                            <li class="list-group-item">
                              <a href="#"
                                @click="showTermsCondition(cl_index)"
                              >Add Am</a>
                            </li>
                            <li class="list-group-item">
                              <router-link
                                :to="{
                                  path: '/clients/' + client.id
                                }"
                                tag="a"
                              >
                                View
                              </router-link>
                            </li>
                          </ul>
                        </template>
                      </dropdown>
                    </div>
                    <div class="box">
                      <div class="emp-details">
                        <avatar class="mr-3" :username="client.name"></avatar>
                        <router-link
                          class="user-title eclipse-para global-para-link2"
                          :to="{
                            path: '/clients/' + client.id
                          }"
                          tag="a"
                          target='_blank'
                        >
                          {{ client.name }}
                        </router-link>
                        <p class="user-email eclipse-para">
                          {{ client.email }}
                        </p>
                      </div>
                      <div class="box-brand">
                        <div class="brand-para">
                          <p>Brand:</p>
                        </div>
                        <div class="text-right">
                          <p
                            v-for="(brand, brand_index) in client.brand"
                            :key="brand_index"
                            v-text="brand.name"
                          ></p>
                        </div>
                      </div>
                      <div class="footer-box">
                        <div class="footer-box">
                          <div class="usr-role">
                            <p>Account Managers:</p>
                          </div>
                          <div
                            v-if="client.account_manager.length"
                            class="usr-role-name text-right"
                          >
                            <div
                              v-for="(am, am_index) in client.account_manager"
                              :key="am_index"
                              :class="
                                avatarClass(client.account_manager, am_index)
                              "
                            >
                            <a :title="am.name"> 
                              <avatar
                                :inline="true"
                                :username="am.name"
                              ></avatar>
                              </a>
                              {{
                                client.account_manager_total_count > 3 &&
                                am_index + 1 > 2
                                  ? "+" + client.account_manager_remaining_count
                                  : ""
                              }}
                            </div>
                          </div>
                          <div v-else class="usr-role-name text-right empty_am">
                            N/A
                          </div>
                        </div>

                        <div class="footer-box">
                          <div class="usr-role">
                            <p>Created on: <b> {{ client.created_at }} </b> </p>
                          </div>
                        </div>

                        <div class="footer-box">
                          <div class="usr-role">
                            <p>Status: <b> {{ client.status }} </b> </p>
                          </div>
                        </div>

                        <div class="footer-box">
                          <div class="usr-role">
                            <p>Login: <b> {{ client.login }} </b> </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12">
                  <no-record-found></no-record-found>
                </div>
              </div>
            </div>
          </transition>

          <div class="row">
            <div class="col-sm-12">
              <pagination
                class="pagination justify-content-center"
                :data="client_list"
                :limit="1"
                @pagination-change-page="getClientData"
              ></pagination>
            </div>
          </div>

          <modal
            name="terms-and-condition-modal"
            height="auto"
            width="60%"
            :scrollable="true"
          >
            <div class="ModalLoader">
              <add-or-remove-am-for-cleient 
                :prop_client_id="prop_client_id"
                @updateClientAmData="updateClientAmData($event)" 
              ></add-or-remove-am-for-cleient>
              <!-- <show-lead-details></show-lead-details> -->
            </div>
        </modal>

        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import Dropdown from "bp-vuejs-dropdown";
import Select2 from "v-select2-component";
import norecordfound from "../../../components/no-record-found";
import Datepicker from "vuejs-datepicker";
import addOrRemoveAmtoClient from "../sub-components/add-am";

export default {
  components: {
    Avatar,
    Dropdown,
    Select2,
    "no-record-found": norecordfound,
    Datepicker,
    "add-or-remove-am-for-cleient" : addOrRemoveAmtoClient,
  },
  data() {
    return {
      loader: true,
      client_loader: true,
      client_list: {},
      formData: {
        search: "",
        brand_id: null,
        employee_id : null,
        from_date : null,
        to_date : null,
        status : null,
      },
      brand_options: null,
      project_employees : null,
      status_options: [
        {
          text : 'Satisfied',
          id : 'satisfied',
        },
        {
          text : 'Angry',
          id : 'angry',
        },
        {
          text : 'Refunded',
          id : 'refunded',
        },
        {
          text : 'Sleeping',
          id : 'sleeping',
        },
        {
          text : 'DNC',
          id : 'dnc',
        },
        {
          text : 'Retainer',
          id : 'retainer',
        },
        {
          text : 'Default',
          id : 'default',
        }
      ],
      prop_client_index : null,
      prop_client_id : null,
    };
  },
  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
    this.getemployees();
    this.getClientData();
    this.getprojectbrands();
    this.loader = false;
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  methods: {
    updateClientAmData(amData) {
      // console.log(amData);
      this.$set(this.client_list.data, this.prop_client_index, amData.data);
    },
    showTermsCondition(message_index) {
      // alert(message_index);
      this.prop_client_index = message_index;
      this.prop_client_id = this.client_list.data[this.prop_client_index].id;
      // console.log(this.prop_client_id);
      // this.prop_auto_reply_message_body = this.auto_reply_messages.data[this.prop_auto_reply_message_index].body;
      this.$modal.show("terms-and-condition-modal");
    },
    hideTermsCondition() {
      this.$modal.hide("terms-and-condition-modal");
    },
    onClearDateFromDate() {
      this.formData.from_date = null;
    },
    onClearDateToDate() {
      this.formData.to_date = null;
    },
    customFormatterfromdate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.from_date = new_val;
      return new_val;
    },
    customFormattertodate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.to_date = new_val;
      return new_val;
    },
    async getemployees() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-employees"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_employees = response.data.data;
        }
      });
    },
    refreshClientTable() {
      this.getClientData();
      this.getprojectbrands();
      this.loader = false;
    },
    async getprojectbrands() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-project-brand"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.brand_options = response.data.data;
        }
      });
    },
    async getClientData(page = 1, action = null) {
      const $this = this;
      if (action == "search") {
        if ($this.formData.search.length < 2 && $this.formData.search != "") {
          return true;
        }
      }
      $this.client_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "clients?" +
          "&page=" +
          page +
          "&search=" +
          $this.formData.search +
          "&brand_id=" +
          $this.formData.brand_id +
          "&employee_id=" +
          $this.formData.employee_id +
          
          "&from_date=" +
          $this.formData.from_date +

          "&to_date=" +
          $this.formData.to_date +

          "&status=" +
          $this.formData.status +
          
          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.client_list = response.data;
        }
        $this.client_loader = false;
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    avatarClass(managers, index) {
      if (managers.length == 1) {
        return "custom-avtr orv-3";
      } else if (managers.length == 2) {
        return "custom-avtr orv-" + (index + 2);
      } else {
        return "custom-avtr orv-" + (index + 1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.employee-index {
  .general-filters {
    .form-group {
      width: 95%;
      .input-group {
        input {
          border-radius: 3px !important;
          background: #f9f9f9;
          letter-spacing: 0.28px;
          font-size: 14px;
          padding: 3px 15px;
          color: #364460;
          border: none;
          font-weight: 200;
          height: auto;
        }
        button {
          background: #f9f9f9;
          color: #3b4c54;
          border: none;
          padding: 4px 8px;
        }
      }
    }
    .form-actions {
      width: 100%;
      button {
        width: 100%;
        margin-left: 0;
        padding: 5px 0;
      }
    }
    span.select2.select2-container.select2-container--default {
      width: 100% !important;
    }
  }
  .horizontal-row {
    padding: 30px 0 30px;
    hr {
      margin: 0;
    }
  }
  .wrap-box {
    background: #faf9f7;
    border-radius: 3px;
    padding: 0 20px;
    margin-bottom: 30px;
    .header-top {
      position: absolute;
      right: 26px;
      z-index: 100;
      top: 19px;
      .bp-dropdown__btn {
        border: none;
      }
      .bp-dropdown__body {
        padding: 0;
        background-color: #fff;
        box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
        z-index: 9999;
        border-radius: 4px;
        border: none;
        margin-top: 10px;
        .list-group {
          .list-group-item {
            border: none;
            position: relative;
            display: block;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            padding: 10px 20px 10px 7px;
            text-align: left;
            a {
              color: #212529;
            }
          }
        }
      }
    }
    .emp-details {
      position: relative;
      padding: 20px 0 20px 44px;
      p {
        margin: 0;
        &.user-title {
          font-size: 12px;
        }
        &.user-email {
          font-size: 12px;
        }
      }
      .vue-avatar--wrapper.mr-3 {
        width: 32px !important;
        height: 32px !important;
        position: absolute;
        top: 23px;
        left: 0;
        span {
          letter-spacing: 0.24px;
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    .box-brand {
      padding-bottom: 10px;
      div {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        &.brand-para {
          p {
            margin: 0;
          }
        }
        &.text-right {
          p {
            margin: 0;
          }
        }
      }
    }
    .projects-list {
      p {
        font-weight: bold;
      }
    }
    .footer-box {
      display: block;
      position: relative;
      width: 100%;
      padding: 12px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      &:first-child {
        border-top: none;
        padding-top: 2px;
      }
      .usr-role {
        p {
          margin: 0;
          letter-spacing: 0.24px;
          color: #918a82;
          font-size: 12px;
          b {
            float:right;
          }
        }
      }
      .usr-role-name {
        position: absolute;
        right: -35px;
        top: 0;
        .custom-avtr {
          display: inline-block;
          position: relative;
          &.orv-1 {
          }
          &.orv-2 {
            left: -11px;
          }
          &.orv-3 {
            left: -22px;
          }
          &.orv-4 {
            left: -34px;
          }
          .vue-avatar--wrapper {
            margin: 0 !important;
            width: 25px !important;
            height: 25px !important;
            padding: 3px 4px 0 !important;
            span {
              font-size: 11px;
            }
          }
        }
        &.empty_am {
          right: 0;
        }
      }
    }
  }
  .justify-content-center {
    margin-bottom: 0;
  }
}

// Media Query Start

// Extra large devices (lg and less)
@media screen and (max-width: 1199px) {
}
// Extra large devices (md and less)
@media screen and (max-width: 991px) {
}

// Extra large devices (sm and up)
@media screen and (max-width: 767px) {
  body .top-gen-heading {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

// Extra large devices (xs and up)
@media screen and (max-width: 575px) {
  .employee-index .general-filters .form-group {
    width: 100%;
  }
}
// Extra large devices (xl, 1200px and up)
@media (min-width: 1200) {
}
</style>
